.top-5-widget-card > .ant-card-body {
	padding: 12px;
}

.top-5-widget-card > .ant-card-head {
	padding: 12px;

	font-size: 14px;
}

.top-5-widget-container {
	display: grid;
	grid-template-columns: 1fr 15px 1fr 1fr;
}

.top-5-widget-container > .graph-container {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;

	display: flex;
	justify-content: flex-start;
	align-items: flex-start;

	padding-bottom: 20px;
}

.top-5-widget-container > .title-container {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 4;
}

.top-5-widget-container > .statistic-container {
	grid-column-start: 3;
	grid-column-end: 5;
	grid-row-start: 1;
	grid-row-end: 4;
}
