html,
body {
	height: 100%;
	margin: 0;
}

* {
	box-sizing: border-box;
}

.ant-checkbox-disabled + span {
	color: #000;
	cursor: pointer;
}

.ant-modal {
	margin: 0 auto;
	width: fit-content !important;

	min-width: 300px !important;
	max-width: 80vw !important;
}

.firmware-drop-zone {
	height: 100%;
	width: 100%;
	min-width: 50% !important;

	min-height: calc(166px + 47px);

	border: 1px solid #d9d9d9 !important;
	border-radius: 8px !important;

	display: flex;
	flex-direction: column;
	flex: 1 1 0;

	align-items: center;
	justify-content: center;

	padding: 50px !important;
	box-sizing: border-box;
}

.firmware-drop-zone svg {
	width: 100%;
	height: 80px;
}

.firmware-drop-list {
	width: 100%;
	min-height: 150px;
}

.drop-zone_close {
	transition: all 0.8s ease;
}
.drop-zone_close:hover {
	color: #adc6ff;
}

.pagination {
	display: flex;
	flex-direction: row;
}

.pagination > li {
	width: 32px;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell::before {
	height: 85% !important;

	background-color: #c9c9c9;
}

.process-table tbody tr:not(:nth-child(1)) td {
	height: 25px !important;
	padding: 5px 16px;
}

.version-table tbody tr:not(:nth-child(1)) td {
	height: 25px !important;
	padding: 5px 16px;
}


.error_only__disabled > svg > path:first-child {
	display: none;
}
